import { AppContext, clearCart } from '../../config/context'
import React, { useContext, useEffect, useState } from 'react'

import { Container } from '../Container'
import { Divider } from '../Divider'
import { Loader } from '../Loader'
import { PageContent } from '../PageContent'
import { SPACING } from '../../helpers/constants'
import StyledConfirmation from './style'
import queryString from 'query-string'
import { readKlarnaOrder } from '../../helpers/api'
import { renderKlarnaSnippet } from '../../helpers/checkout'
import { useTranslation } from '../../hooks/useTranslation'

const getQueryParams = () =>
  typeof window !== 'undefined' && window.location
    ? queryString.parse(window.location.search)
    : {}

const Confirmation = () => {
  const [_, dispatch] = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const getTranslation = useTranslation()

  useEffect(() => {
    const { order_id } = getQueryParams()
    if (order_id) {
      readKlarnaOrder(order_id)
        .then(order => {
          if (order.html_snippet) {
            renderKlarnaSnippet('klarna-confirmation-container', order)
            localStorage.removeItem('checkoutOrder')
            dispatch(clearCart())
          } else {
            setErrorMessage(getTranslation('noConfirmationHtmlSnippetMessage'))
          }
        })
        .catch(error => {
          console.log({ error })
          setErrorMessage(getTranslation('generalConfirmationError'))
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
      setErrorMessage(getTranslation('noOrderIdErrorMessage'))
    }
  }, [])

  return (
    <Container narrowest>
      <PageContent>
        <StyledConfirmation>
          {loading && <Loader />}
          {errorMessage && (
            <StyledConfirmation.Error>
              <div
                dangerouslySetInnerHTML={{
                  __html: errorMessage,
                }}
              />
            </StyledConfirmation.Error>
          )}
          <div id={'klarna-confirmation-container'} />
        </StyledConfirmation>
      </PageContent>
      <Divider size={SPACING.LARGE} />
    </Container>
  )
}

export { Confirmation }
