import React from 'react'
import Layout from '../views/Layout'
import SEO from '../views/Seo'
import { Confirmation } from '../components/Confirmation'
import Main from '../views/Main'
import { useTranslation } from '../hooks/useTranslation'

const CheckoutConfirmation = () => {
  const getTranslation = useTranslation()

  return (
    <Layout hideFooter showCheckoutHeader>
      <SEO title={getTranslation('checkoutConfirmationPageTitle')} />
      <Confirmation />
    </Layout>
  )
}

export default CheckoutConfirmation
