import styled from 'styled-components'
import { colors, spacing, mq } from '../../styles/utilities/variables'

const StyledConfirmation = styled.div`
  background: ${colors.white};
  padding: ${spacing.xxLarge};

  @media ${mq.mobile} {
    padding: ${spacing.small};
  }
`

StyledConfirmation.Error = styled.div`
  background: ${colors.primaryRed};
  color: ${colors.white};
  padding: ${spacing.medium};
`

export default StyledConfirmation
